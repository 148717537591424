import React, { Suspense } from "react";
import { createRoot } from "react-dom";
import { calculatePublicPath } from "canopy-sofe-extensions";
import App from "./app.component.js";
import Badge from "./badge.component.js";
import { queryClient } from "fetcher!sofe";

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then(d => ({
    default: d.ReactQueryDevtools,
  }))
);

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appDisplayed: false,
      showQueryDevtools: localStorage.getItem("cp:sofe-inspector:show-query-devtools") === "true",
    };
  }

  render() {
    // Doesn't hurt to show the badge even when the app is open, since it's z-index is smaller than App's
    const { appDisplayed, showQueryDevtools } = this.state;

    return (
      <div>
        <App
          toggleAppVisibility={this.toggleAppVisibility}
          hideApp={this.hideApp}
          visible={appDisplayed}
          queryDevtoolsVisible={showQueryDevtools}
          onToggleQueryDevtools={this.toggleQueryDevtools}
          {...this.props}
        />
        <Badge toggleAppVisibility={this.toggleAppVisibility} {...this.props} />
          {showQueryDevtools && (
            <Suspense fallback={null}>
              <div style={{ position: "fixed", zIndex: 100002 }}>
                <ReactQueryDevtoolsProduction client={queryClient} />
              </div>
            </Suspense>
          )}
      </div>
    );
  }

  hideApp = () => {
    this.setState({
      appDisplayed: false,
    });
  };

  toggleAppVisibility = () => {
    this.setState((prevState) => ({ appDisplayed: !prevState.appDisplayed }));
  };

  toggleQueryDevtools = () => {
    this.setState((prevState) => {
      const newShowQueryDevtools = !prevState.showQueryDevtools;
      localStorage.setItem("cp:sofe-inspector:show-query-devtools", newShowQueryDevtools);
      return { showQueryDevtools: newShowQueryDevtools };
    });
  };
}

__webpack_public_path__ = calculatePublicPath("sofe-inspector"); // eslint-disable-line

export default function boot(props) {
  let wrapper = document.createElement("div");
  wrapper.id = "sofe-inspector";

  document.body.appendChild(wrapper);
  const root = createRoot(wrapper);
  root.render(<Root {...props} />);
}
