import React from "react";
import { CpTabs, CpButton, CpToggle } from "canopy-styleguide!sofe";
import { Scoped, k } from "kremling";
import SofeOverrides from "./sofe/sofe-overrides.component.js";
import FeatureToggles from "./feature/feature-toggles.component.js";
import {
  getServiceOverrides,
  clearAllSofeOverrides,
} from "./sofe/service.resource.js";
import {
  getToggleOverrides,
  clearAllToggleOverrides,
} from "./feature/feature-toggles.resource.js";
import InspectorOptions from "./inspector-options.component.js";
import SetSquadDialog from "./set-squad-dialog.component.js";
import Snapshots from "./snapshots.component.js";
import { isEmpty, capitalize } from "lodash";
import { combineLatest, from } from "rxjs";
import { map } from "rxjs/operators";
import BackendOverrides from "./backend-overrides/backend-overrides.component.js";
import NetworkOverrides from "./network-overrides/network-overrides.component.js";
import Performance from "./performance.component.js";

export default class App extends React.Component {
  state = {
    squad: window.localStorage.getItem("canopy-squad"),
    selectSquadVisible: false,
    snapshots: JSON.parse(localStorage.getItem("sofe-snapshots")) || {},
    detached: this.props.detached || false,
    activeTabId: "sofe-overrides",
  };

  componentDidMount() {
    if (!this.state.squad) this.setState({ selectSquadVisible: true });
    window.addEventListener("keyup", this.toggleApp);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.toggleApp);
    this.promisesOb && this.promisesOb.unsubscribe();
  }

  render() {
    const { squad, selectSquadVisible, detached, activeTabId } = this.state;
    const { visible, onToggleQueryDevtools, queryDevtoolsVisible } = this.props;

    if (!visible) return null;

    return (
      <Scoped css={css}>
        <div className="canopy-inspector">
          <div
            className=""
            style={{
              width: "100%",
              float: "left",
              height: "100%",
              position: "relative",
              minHeight: 1,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            {selectSquadVisible && (
              <SetSquadDialog
                squad={squad}
                close={() => {
                  this.setState({
                    selectSquadVisible: false,
                  });
                }}
                setSquad={(squad) => {
                  this.setState({
                    squad,
                  });
                  localStorage.setItem("canopy-squad", squad);
                  this.sofeOverridesComponent.setSquad(squad);
                }}
              />
            )}
            <div style={{ display: "flex", gap: "1.2rem", margin: "1.2rem" }}>
              <h1 style={{ margin: 0, flex: 1 }}>
                Canopy Inspector{" "}
                {this.props.env &&
                  this.state.detached &&
                  `: ${capitalize(this.props.env)}`}
              </h1>
              <div className="self-center flex cp-gap-8 items-center cp-mb-8">
                <CpToggle
                  id="rq-devtools-toggle"
                  checked={queryDevtoolsVisible}
                  onChange={onToggleQueryDevtools}
                />
                <label htmlFor="rq-devtools-toggle" className="cp-caption">
                  Query Devtools
                </label>
              </div>
              <div
                style={{
                  margin: 8,
                  cursor: "pointer",
                  borderBottom: `4px solid ${squad}`,
                }}
                onClick={() => this.setState({ selectSquadVisible: true })}
              >
                Squad: {squad}
              </div>
              <CpButton
                btnType="icon-dark"
                onClick={this.clearAllOverrides}
                icon="crud-trash-large"
              />
              {!detached && (
                <CpButton
                  btnType="icon-dark"
                  onClick={this.toggleOverlay}
                  icon="misc-crosshairs"
                />
              )}

              <CpButton
                icon="misc-camera"
                btnType="icon-dark"
                onClick={() => this.snapshot(true)}
              />
              <CpButton
                icon="close-circle-open"
                btnType="icon-dark"
                onClick={!detached ? this.props.hideApp : window.close}
              />
            </div>
            <CpTabs
              border
              dark
              activeId={activeTabId}
              onChange={(id) => this.setState({ activeTabId: id })}
            >
              <CpTabs.Button id="sofe-overrides">Sofe overrides</CpTabs.Button>
              <CpTabs.Button id="feature-toggles">
                Feature toggles
              </CpTabs.Button>
              <CpTabs.Button id="network-overrides">
                Network overrides
              </CpTabs.Button>
              <CpTabs.Button id="backend-overrides">
                Backend overrides
              </CpTabs.Button>
              <CpTabs.Button id="performance">Performance</CpTabs.Button>
              <CpTabs.Button id="other-options">Other options</CpTabs.Button>
              <CpTabs.Button id="snapshots">Snapshots</CpTabs.Button>
            </CpTabs>
            {activeTabId === "sofe-overrides" && (
              <SofeOverrides
                squad={squad}
                visible={this.props.visible}
                ref={(el) => (this.sofeOverridesComponent = el)}
              />
            )}
            {activeTabId === "feature-toggles" && (
              <FeatureToggles
                ref={(el) => (this.featureTogglesComponent = el)}
              />
            )}
            {activeTabId === "network-overrides" && <NetworkOverrides />}
            {activeTabId === "backend-overrides" && <BackendOverrides />}
            {activeTabId === "performance" && <Performance />}
            {activeTabId === "other-options" && <InspectorOptions />}
            {activeTabId === "snapshots" && (
              <Snapshots
                updateSnaps={this.updateSnaps}
                snapshots={this.state.snapshots}
              />
            )}
          </div>
        </div>
      </Scoped>
    );
  }

  toggleOverlay() {
    const enabled = localStorage.getItem("cp:single-spa:overlay");

    if (enabled) {
      localStorage.removeItem("cp:single-spa:overlay");
    } else {
      localStorage.setItem("cp:single-spa:overlay", "true");
    }

    window.dispatchEvent(new CustomEvent("cp:show-overlay-keypress"));
  }

  toggleApp = (e) => {
    if (e.keyCode === 49 && e.ctrlKey && e.shiftKey) {
      // Toggle partial screen
      if (this.props.visible) {
        this.props.toggleAppVisibility();
      }
    } else if (e.keyCode === 50 && e.ctrlKey && e.shiftKey) {
      this.props.toggleAppVisibility();
    }
  };

  clearAllOverrides = () => {
    if (confirm("Are you sure you want to clear all overrides?")) {
      clearAllSofeOverrides();
      if (this.sofeOverridesComponent) {
        this.sofeOverridesComponent.refreshServices();
      }

      clearAllToggleOverrides();
      if (this.featureTogglesComponent) {
        this.featureTogglesComponent.refreshToggles();
      }
    }
  };

  snapshot = () => {
    const existingSnaps = localStorage.getItem("sofe-snapshots") || {};

    this.promisesOb = combineLatest(
      from(getToggleOverrides()),
      from(getServiceOverrides())
    )
      .pipe(
        map((combined) => {
          const [toggles, overrides] = combined;
          return { toggles, overrides };
        })
      )
      .subscribe((snap) => {
        const snapList = !isEmpty(existingSnaps)
          ? JSON.parse(existingSnaps)
          : {};
        const updatedSnaps = {
          ...snapList,
          [Object.keys(snapList).length]: snap,
        };

        localStorage.setItem("sofe-snapshots", JSON.stringify(updatedSnaps));

        this.updateSnaps(updatedSnaps);
      });
  };

  updateSnaps = (snapshots) => this.setState({ snapshots });

  componentDidUpdate(prevState) {
    if (this.props.visible) {
      const commonDepsCookie = document.cookie
        .split(";")
        .filter((cookie) => cookie.trim().indexOf("cp:common-deps") === 0);
      const localStorageCommonDeps = localStorage.getItem("common-deps");
      if (
        !localStorageCommonDeps ||
        (localStorageCommonDeps === "dev" && commonDepsCookie.length === 0)
      ) {
        // default common-deps to dev, will default to react development builds
        localStorage.setItem("common-deps", "dev");
        document.cookie = `cp:common-deps=dev; Path=/`;
      }
    }
  }
}

const css = k`
  & .canopy-inspector {
    font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    color: #ffffff;
    background-color: #222222;
    height: 50rem;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 2147483001;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06), 0 2px 6px 0 rgba(0, 0, 0, 0.26);
  }

  & .canopy-inspector--detached {
    height: 100%;
  }
`;
